.SortFilterMultiColumnValueList {
	&__valueList {
		.attributeValueGroupList {
			display: grid;
			grid-auto-flow: column;
			grid-template-rows: repeat(var(--valueListWithColumns-rows), 32px);

			a {
				min-width: 130px;
			}
		}
	
		&--threeColList {
			.attributeValueGroupList {
				grid-template-columns: repeat(3, 33.33%);
			}
		}
	
		&--fourColList {
			.attributeValueGroupList {
				grid-template-columns: repeat(4, 25%);
			}
		}
	}
}
