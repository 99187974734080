.SortFilterPanelGrid {
	--imageDimension: 65px;
	--panelSize: 215px;

	display: grid;
	grid-gap: 7px;
	grid-template-columns: repeat(2, var(--panelSize));

	// mobile mode
	.SortFilterNestedDrawer & {
		grid-template-columns: 100%;
		padding: 0 10px;
	}

	&--multiSelect {
		--panelSize: 256px;
	}

	// single-select or multi-select
	a,
	.fieldCheckbox > input + label {
		align-items: center;
		display: flex;
	}

	a {
		padding: 16px 10px;
	}

	.fieldCheckbox.field {
		box-sizing: border-box;
		padding: 0;

		> label {
			box-sizing: border-box;
			padding: 16px 10px 16px 40px;
			width: 100%;

			&:before,
			&:after {
				top: calc(50% - 11px);
			}

			&:before {
				left: 10px;
			}

			&:after {
				left: 15px;
			}

			.SortFilterNestedDrawer & {
				float: none;
			}
		}
	}

	&__panel {
		border: 1px solid #ccc;
		text-decoration: none;

		&:hover {
			background: var(--lightGrey);
			color: var(--baseTextColor);
		}

		@media (-webkit-min-device-pixel-ratio: 2) {
			div {
				border-width: 0.5px;
			}
		}
	}

	&__image {
		height: var(--imageDimension);
		min-width: var(--imageDimension);
		width: var(--imageDimension);
	}

	&__name {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.2em;
		margin-bottom: 3px;

		.SortFilterNestedDrawer & {
			font-size: 18px;
		}
	}

	&__description {
		font-size: 11px;
		line-height: 1.35em;

		.SortFilterNestedDrawer & {
			font-size: 14px;
		}
	}
}
