.DropdownWrapper {
	display: inline-flex;
	position: relative;
}

.Dropdown {
	--defaultDropdownWidth: 232px;

	animation: fadeIn var(--surfaceTransitionDuration) var(--surfaceTimingFunction);
	background-color: var(--baseBackgroundColor);
	border: 1px solid var(--baseBorderColor);
	box-shadow: var(--baseBoxShadow);
	box-sizing: border-box;
	color: var(--baseTextColor);
	position: absolute;
	z-index: 2;

	&-anchor-top {
		bottom: 100%;
	}

	&-anchor-right {
		left: 100%;
	}

	&-anchor-bottom {
		top: 100%;
	}

	&-anchor-left {
		right: 100%;
	}

	&-align-top {
		top: 0;
	}

	&-align-right {
		right: 0;
	}

	&-align-bottom {
		bottom: 0;
	}

	&-align-left {
		left: 0;
	}
}
