// we use "em" on many values because it is responsive to the rem font-size. We increase the rem size on Drawer mode
// so everything gets larger in scale
.SortFilterMinMax {
	border-bottom: 1px solid #ccc;
	font-size: 1rem;
	margin: 7px 0;
	padding-bottom: 15px;

	// Scale everything up on drawer mode
	.SortFilterNestedDrawer & {
		font-size: 1.4rem;
	}

	&__wrapper,
	&__bottomBtnWrapper {
		display: flex;

		.SortFilterNestedDrawer & {
			justify-content: center;
		}
	}

	&__bottomBtnWrapper {
		margin-top: 15px;
	}

	&__label {
		font-size: 1.4em;
		line-height: 2.5em;
		margin-right: .4em;

		&:not(:first-child) {
			margin-left: .35em;
		}

		.SortFilterNestedDrawer & {
			font-weight: 500;
		}
	}

	// needs the input. specificity
	input.SortFilterMinMax__input {
		border: 1px solid #999;
		box-sizing: border-box;
		font-size: 1.4em;
		height: 2.5em;
		padding-left: .35em;
		width: 3.35em;

		.SortFilterNestedDrawer & {
			width: 4em;
		}
	}

	&__arrowApplyBtn {
		background: #999;
		border: 1px solid #999;
		color: #fff;
		font-size: 1.4em;
		height: 2.5em;
		margin-left: .35em;
		padding: 0;
		position: relative;
		width: 2.05em;

		&:after {
			border-bottom: 2px solid #fff;
			border-right: 2px solid #fff;
			content: "";
			display: block;
			height: .5em;
			left: .5em;
			position: absolute;
			top: .85em;
			transform: rotate(-45deg);
			width: .5em;
		}
	}
}