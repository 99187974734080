@import '../../../../style/mixins';

$label-hide-delay: .5s;
$thumb-width: 36px;
$thumb-height: 60px;
$track-height: 10px;

$thumb-width-desktop: 20px;
$thumb-height-desktop: 52px;

.RangeSlider {
	box-sizing: border-box;
	display: flex;
	height: 36px;
	padding: 0 18px;
	user-select: none;
	width: 100%;

	&__track {
		align-self: center;
		background: white;
		border: 1px solid var(--baseContrastBorderColor);
		border-radius: 7px;
		height: $track-height;
		position: relative;
		width: 100%;
	}

	&__thumb {
		background: #fff;
		border: 1px solid var(--baseContrastBorderColor);
		border-radius: 10px;
		height: $thumb-height;
		width: 100%;

		@include media(desktop) {
			height: $thumb-height-desktop;
		}

		&-wrapper {
			align-items: center;
			display: flex;
			height: 20px;
			justify-content: center;
			width: $thumb-width;

			@include media(desktop) {
				width: $thumb-width-desktop;
			}
		}

		&Label {
			background-color: #000;
			border-radius: 4px;
			color: #fff;
			display: block;
			font-family: var(--fontHeading);
			font-size: 1.45rem;
			font-weight: 500;
			padding: 4px;
			position: absolute;
			top: -55px;
			white-space: nowrap;

			@include media(desktop) {
				top: -50px;
			}

			&--hidden {
				// Focus ring remains if inside viewport
				transform: translateX(-9999px);
				transition: visibility 0s $label-hide-delay, transform 0s $label-hide-delay;
				visibility: hidden;
			}
		}
	}
}