.SortFilterAppliedFilterClearButton {
	align-items: center;
	display: flex;
	font-size: 1.8rem;
	outline-offset: -1px;
	text-transform: none;
	white-space: nowrap;

	.lpIcon-close01 {
		font-size: 2.3rem;
		margin-right: 5px;
	}

	&__container {
		margin: 0 10px 10px 0;
		max-width: 100%;
	}

	span {
		vertical-align: middle;
	}

	// duplicate the same class to override default Button style
	&#{&} {
		padding: 8px 10px;
	}

	@media (max-width: 414px) and (orientation: portrait) {
		display: block;
		overflow: hidden;
    	text-overflow: ellipsis;
 	}
}
