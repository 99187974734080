.SortFilterDropdownContent {
	--dropdown-content-height: 375px;
	--dropdown-content-width: var(--defaultDropdownWidth);
	--dropdown-content-padding-left-right: 10px;
	--dropdown-content-padding-top-bottom: 7px;

	box-sizing: border-box;
	max-height: var(--dropdown-content-height);
	overflow: hidden;
	overflow-y: auto;
	padding: var(--dropdown-content-padding-top-bottom) var(--dropdown-content-padding-left-right);
	width: var(--dropdown-content-width);
	will-change: scroll-position;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		background-color: #f1f1f1;
		width: 8px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ccc;
		border-radius: 4px;
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}

	&--multiSelect {
		--dropdown-content-width: calc(var(--defaultDropdownWidth) + 30px);
		--dropdown-content-padding-left-right: 15px;

		max-height: calc(var(--dropdown-content-height) - var(--footer-height));

		&:not(.SortFilterDropdownContent--multipleMenu):not(.SortFilterDropdownContent--overflowing) {
			padding-bottom: 5px;
		}

		&.SortFilterDropdownContent--overflowing {
			border-bottom: 1px solid #ccc;
		}
	}

	&--multiColumn {
		max-height: calc(100vh - 150px);
	}

	&--multiColumnTwoColumns {
		width: max-content;
	}

	&--multiColumnThreeColumns {
		width: 500px;
	}

	&--multipleMenu {
		display: flex;
		max-height: 100%;
		width: auto;

		// Since the attribute group submenu width is usually small, make the image it's natural width
		.SortFilterThumbnail__attrGroupBodyImage {
			max-width: var(--dropdown-content-width);
			width: auto;
		}
	}

	&--flexibleWidth {
		--dropdown-content-width: max-content;
	}
}
