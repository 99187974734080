@import '../../../../../Content/global/searchMixins';

#SearchBarFilter {
	display: inline-flex;

	.searchContainer {
		// repeating all the styles for specificity sake
		@include searchContainer;
		display: inline-flex;
		margin-left: 0;

		.searchInput {
			@include searchInput;
			height: 36px;
			&:focus {
				outline-offset: -2px;
			}
		}

		.searchArrowBtn {
			@include searchArrowBtn;
			height: 34px;

			&::before {
				top: 8px;
			}
		}
	}

	&.SearchBarFilter--mobile {
		display: block;
		padding: 20px 10px;

		.field {
			padding-bottom: 0;
		}

		.fieldHelper {
			margin-bottom: 0;
		}
	}
}
