$attrGroupFooterHeight: 75px;
$attrGroupFooterShadowOffset: 10px;

.SortFilterNestedDrawer {
	header {
		.Overlay__contentWrapper__closeButton {
			align-items: center;
			display: flex;
			// fixes out of bounds outline on mobile
			outline-offset: -1px;
			padding: 0 10px;
			position: static;
			text-transform: uppercase;
			width: auto;

			.caret {
				border-width: 2px;
				height: 14px;
				margin-right: 4px;
				width: 14px;
			}
		}
	}

	main:not(.SortFilterNestedDrawer__attributeList) {
		padding: 10px;
	}

	main.SortFilterNestedDrawer__attributeList {
		padding: 0 0 10px;
	}

	&--noFooter .OverlayContent {
		--overlayContentStickyFooterHeight: 0;
	}

	.OverlayContent {
		--overlayContentStickyFooterHeight: $attrGroupFooterHeight;

		background: #fff;

		footer {
			background: #fff;
			box-shadow: 0 -3px $attrGroupFooterShadowOffset 0 rgba(0, 0, 0, 0.1);

			.Button {
				margin: 0 6px;
				outline-offset: -1px;
				width: 137px;

				&__loadingIcon {
					vertical-align: top;
				}
			}
		}
	}

	main:not(.SortFilterNestedDrawer__attributeList),
	footer {
		.Button {
			font-size: 15px;
			height: 38px;
		}
	}
}
