@import '../style-guide';
@import '../utilities.scss';

textarea {
	&.error {
		background: $errorBackgroundColor;
		outline: $fieldHighlightWidth solid $fieldHighlightColorInvalid;
		outline-offset: -$fieldHighlightWidth;
	}
}

input:not([type='hidden'], [type='checkbox'], [type='radio']),
select {
	&.error {
		background: $errorBackgroundColor;
		outline: $fieldHighlightWidth solid $fieldHighlightColorInvalid;
		outline-offset: -$fieldHighlightWidth;
	}
}

.fieldComposite {
	&.error {
		background: $errorBackgroundColor;
		margin-top: 0;
		outline: $fieldHighlightWidth solid $fieldHighlightColorInvalid;
		outline-offset: -$fieldHighlightWidth;

		input,
		select,
		textarea {
			background: transparent !important;
			outline: 0 !important;
		}
	}

	&.focus {
		outline: $fieldHighlightWidth solid $fieldHighlightColor !important;
		outline-offset: -$fieldHighlightWidth;

		input:focus,
		select:focus,
		textarea:focus {
			outline: 0 !important;
		}
	}

	&.fcdSelectCaret.error {
		background: $errorBackgroundColor;
	}
}

.fieldCheckbox {
	input[type='checkbox'] {
		&:focus ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff,
				0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
			transition: none;
		}

		&.error ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff, 0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorInvalid;
			transition: none;
		}
	}
}

.fieldRadio {
	input[type='radio'] {
		&:focus ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff,
				0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorCheckboxRadio;
			transition: none;
		}

		&.error ~ label::before {
			box-shadow: 0 0 0 $fieldHighlightWidth #fff, 0 0 0 (2 * $fieldHighlightWidth) $fieldHighlightColorInvalid;
			transition: none;
		}
	}
}

.validationMessage {
	.error:not(:empty) {
		background: $errorBackgroundColor;
		border: 1px solid $errorBorderColor;
		border-radius: 3px;
		color: $errorTextColor;
		display: inline-block;
		font-size: 13px;
		line-height: normal;
		padding: 3px 5px;
		position: absolute;
		z-index: 1;
	}
}
