.SortFilterAppliedFilter {
	background: #fff;
	border-bottom: 1px solid #bbb;
	display: flex;
	flex-wrap: wrap;
	padding: 10px 17px 14px;

	&__filterValue {
		font-size: 1.8rem;
		line-height: 1.5;
	}
}
