.brokenImage,
.brokenImageRetry {
	img { visibility: hidden; }
}

.brokenImage {
	background: none !important;
	cursor: default !important;
	
	&:before {
		border: 1px solid #ccc;
		color: #666;
		content: 'Unable to Load Images';
		display: block;
		font-size: 20px;
		height: 141px;
		left: 0;
		padding-top: 105px;
		position: absolute;
		text-align: center;
		top: 0;
		width: 307px;
		z-index: -1;
	}
	&:after {
		background: url('/img/global/icon-alert.jpg') no-repeat top center;
		content: '';
		display: block;
		height: 35px;
		left: 0;
		margin-top: 50px;
		position: absolute;
		top: 0;
		width: 100%;
	}
	div:not(.brokenImageLink) { display: none; }
	.brokenImageLink {
		cursor: pointer;
		height: 32px;
		left: 0;
		margin: 0 auto;
		padding: 0;
		position: absolute;
		top: 150px;
		width: 310px;
		&:after {
			border: 1px solid #666;
			color: #666;
			content: 'Retry';
			cursor: pointer;
			display: block;
			margin: 0 auto;
			padding: 5px;
			text-transform: uppercase;
			width: 140px;
		}
	}
}