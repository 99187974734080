.SortFilterAttributeGroup {
	&__header {
		+ .SortFilterAttributeMultiValueList {
			margin-top: 10px;

			.SortFilterAttributeValue {
				font-weight: normal;
				white-space: nowrap;
			}
		}

		.SortFilterAttrGroupDrawer & {
			font-weight: 500;
		}
	}

	.SortFilterThumbnail__attrGroupBodyImage {
		border: 0;
		border-radius: 0;
		display: block;
		margin: 0 auto 20px;

		.SortFilterAttrGroupGridView & {
			margin-top: 10px;
		}

		.SortFilterNestedDrawer__attributeList & {
			margin: 20px auto;
		}
	}

	&__inlineAttrContainer {
		&:not(:first-child) {
			margin-top: 18px;
		}

		&--topBorder:not(:first-child) {
			border-top: 1px solid var(--baseBorderColor);
			padding-top: 18px;
		}

		.SortFilterAttrHeader {
			font-size: 1.1em;

			.Dropdown & {
				font-weight: 500;
			}

			+ .SortFilterAttributeValueList {
				margin-top: 4px;
			}

			+ div,
			+ .SortFilterAttributeMultiValueList {
				margin-top: 16px;
			}
		}
	}
}
