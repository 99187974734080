@import '../../../../style/mixins';

.RangeMinMaxLabels {
	display: flex;
	font-size: 1.9rem;
	font-weight: 500;
	justify-content: space-between;
	margin: 25px 0;
	padding: 0 18px;
	user-select: none;

	@include media(desktop) {
		font-size: 1.2rem;
		margin-top: 16px;
	}
}
