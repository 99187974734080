.AppProductPriceLegalNotice {
	&:hover &__trigger {
		z-index: 9002;
	}
	.lpTooltip {
		color: #333;
		font-weight: normal;
		line-height: 25px;
		text-transform: none;
		width: 530px;

		&__inner {
			padding: 20px;
		}
	}
}
