.SortFilterAttrGroupGridView {
	--dropdownMaxHeight: 392px;
	--gridContainerPadding: 10px;
	--gridColumnGap: 0px;
	--gridRowGap: 0px;
	--gridCellPadding: 10px 10px 5px;
	--thumbnailBorderWidth: 1px;
	--gridCheckboxTop: calc(var(--gridImageHeight) + 6px);
	// adds extra px's for wider text. Useful for smaller image grids since text will wrap underneath it.
	--gridExtraCellWidth: 0px;
	// content-box width, doesnt include padding/margin
	--gridCellWidth: calc(var(--gridImageWidth) + var(--gridExtraCellWidth));

	&--twoColumns {
		--gridColumnCount: 2;
	}

	&--threeColumns {
		--gridColumnCount: 3;
	}

	&--fourColumns {
		--gridColumnCount: 4;

		&.SortFilterAttrGroupGridView--70x70 {
			--gridExtraCellWidth: 25px;
		}
	}

	&--fiveColumns {
		--gridColumnCount: 5;
	}

	// hardcoded css variables retains skeleton animation width and height on first render.
	&--96x64 {
		--gridImageWidth: 96px;
		--gridImageHeight: 64px;
	}

	&--135x90 {
		--gridImageWidth: 135px;
		--gridImageHeight: 90px;
	}

	&--150x100 {
		--gridImageWidth: 150px;
		--gridImageHeight: 100px;
	}

	&--70x70 {
		--gridImageWidth: 70px;
		--gridImageHeight: 70px;
	}

	// Show default visible rows for the Thumbnail Grid view dropdown
	&.SortFilterDropdownContent {
		max-height: var(--dropdownMaxHeight);
		width: max-content;

		&--multiSelect {
			max-height: calc(var(--dropdownMaxHeight) - var(--footer-height));
		}
	}

	&.SortFilterNestedDrawer {
		--gridCellPadding: 10px;
	}

	&.SortFilterDropdownContent {
		&--multiSelect {
			--gridColumnGap: 10px;
			--gridRowGap: 10px;
		}
	}

	&.SortFilterNestedDrawer {
		&--multiSelect {
			--gridColumnGap: 10px;
			--gridRowGap: 15px;
		}
	}
}
