.DrawerDisclosure {
	border-radius: 5px;
	font-size: 14px;
	height: 36px;
	padding: 5px 0;

	.Button__label {
		align-items: center;
		display: flex;
		padding: 0 12px;

		img {
			margin-right: 5px;
		}
	}

	&__img {
		&-sort-icon {
			width: 11px;
		}

		&-filter-icon {
			width: 16px;
		}
	}
}