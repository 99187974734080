@import '../SortFilterAttributeGridVars';

.SortFilterAttributeValueList {
	--left-margin: 30px;
	--value-vertical-padding: 7.5px;
	--value-horizontal-padding: 10px;

	list-style: none;
	margin: 0;

	.SortFilterNestedDrawer & {
		--value-vertical-padding: 13.5px;
	}

	a,
	.attributeValueGroupHeader {
		color: #000;
		display: block;
		line-height: 1.15em;
	}

	a {
		padding: var(--value-vertical-padding) var(--value-horizontal-padding);

		&:link {
			text-decoration: none;

			&:hover,
			&:focus {
				background: var(--lightGrey);
				color: #000;
			}
		}
	}

	.attributeValueGroupHeader {
		align-items: center;
		cursor: default;
		display: flex;
		padding: var(--value-vertical-padding) 0 calc(var(--value-vertical-padding) - var(--value-vertical-padding) / 2);

		&--indented {
			margin-left: var(--left-margin);
		}

		.SortFilterNestedDrawer & {
			font-size: 18px;

			&--largeTextWithLine {
				font-size: 21px;
				padding-bottom: 10px;
			}
		}

		&--largeTextWithLine {
			border-bottom: 1px solid var(--baseBorderColor);
			font-size: 18px;
			font-weight: 500;
			margin: 0 0 5px 10px;
			padding-bottom: 6px;
		}
	}

	.valueGroupBodyImage {
		margin: var(--value-vertical-padding) var(--value-horizontal-padding);

		&--indented {
			margin-left: var(--left-margin);
		}
	}

	.attributeValueGroupList {
		list-style: none;
		margin: 0;

		&--indented {
			margin-left: var(--left-margin);
		}
	}

	label {
		cursor: pointer;
	}

	.SortFilterAttrGroupGridView &.SortFilterAttributeValueList--gridView {
		display: grid;
		grid-gap: var(--gridRowGap) var(--gridColumnGap);
		grid-template-columns: repeat(var(--gridColumnCount), 1fr);
		justify-content: space-between;

		.SortFilterAttributeValue {
			display: block;
			text-align: center;
		}

		.SortFilterThumbnail {
			border: var(--thumbnailBorderWidth) solid #ccc;
			display: block;
			height: var(--gridImageHeight);
			margin: 0 auto 5px;
			width: var(--gridImageWidth);
		}

		a {
			box-sizing: content-box;
			margin: auto;
			padding: var(--gridCellPadding);
			width: var(--gridCellWidth);

			&:hover,
			&:focus {
				background: var(--lightGrey);
				color: black;
			}

			&:focus .SortFilterThumbnail {
				outline: none;
			}
		}

		&:nth-child(2) {
			a {
				margin: 10px 0;
			}
		}
	}

	.SortFilterNestedDrawer.SortFilterAttrGroupGridView--threeColumns &.SortFilterAttributeValueList--gridView .SortFilterAttributeValue {
		font-size: 15px;
	}

	.SortFilterDropdownContent--multiColumn & {
		.attributeValueGroupList {
			display: grid;
			grid-auto-flow: column;
			grid-template-rows: repeat(var(--numberOfRows), auto);
		}
	}

	.SortFilterDropdownContent--multiColumnOneColumn & {
		.attributeValueGroupList {
			display: block;
		}
	}
}

.SortFilterAttributeValue {
	align-items: center;
	display: flex;
	font-size: 18px;

	&--alignBottom {
		align-items: flex-end;
	}

	.SortFilterAttrGroupDropdown & {
		font-size: 14px;
	}

	.SortFilterNestedDrawer & {
		font-weight: normal;
	}

	&__inner {
		flex-grow: 1;
	}

	&__count {
		font-weight: normal;
		margin-left: 5px;
	}

	&__name {
		line-height: 1.2em;
	}

	.SortFilterThumbnail {
		align-self: center;
	}
}