@import '../../style/mixins';

.Overlay {
	--overlayZIndex: 1000;
	--overlayContentWrapperMarginHorizontal: 0;
	--overlayContentWrapperMarginVertical: 0;
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: var(--overlayZIndex);

	&__underlay {
		background: var(--underlayBackground);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transition-duration: var(--surfaceTransitionDuration);
		transition-timing-function: var(--surfaceTimingFunction);
		width: 100%;
		will-change: opacity;
		z-index: -1;
	}

	&__contentWrapper {
		position: relative;
		transition-duration: var(--surfaceTransitionDuration);
		transition-timing-function: var(--surfaceTimingFunction);
		will-change: opacity;
	}

	// Underlay transitions
	&.Overlay--transition-hide {
		.Overlay__underlay {
			opacity: 0;
			transition-property: opacity;
		}
	}

	&.Overlay--transition-show {
		.Overlay__underlay {
			opacity: 1;
			transition-property: opacity;
		}
	}

	// Content transitions, except for Drawer
	&:not(.Overlay--Drawer) {
		&.Overlay--transition-hide {
			.Overlay__contentWrapper {
				opacity: 0;
				transition-property: opacity;
			}
		}

		&.Overlay--transition-show {
			.Overlay__contentWrapper {
				opacity: 1;
				transition-property: opacity;
			}
		}
	}

	&--Drawer {
		.Overlay__contentWrapper {
			position: absolute;
			transform: none;
			will-change: transform;
		}

		&-fullscreen .Overlay__contentWrapper {
			height: 100%;
			width: 100%;
		}

		&-top {
			.Overlay__contentWrapper {
				left: 0;
				top: 0;
				width: 100%;
			}

			// Content transitions for Drawer
			&.Overlay--transition-hide {
				.Overlay__contentWrapper {
					transform: translateY(-100%);
				}
			}
		}

		&-right {
			.Overlay__contentWrapper {
				height: 100%;
				right: 0;
				top: 0;
			}
			// Content transitions for Drawer
			&.Overlay--transition-hide {
				.Overlay__contentWrapper {
					transform: translateX(100%);
				}
			}
		}

		&-bottom {
			.Overlay__contentWrapper {
				bottom: 0;
				left: 0;
				width: 100%;
			} // Content transitions for Drawer
			&.Overlay--transition-hide {
				.Overlay__contentWrapper {
					transform: translateY(100%);
				}
			}
		}

		&-left {
			.Overlay__contentWrapper {
				height: 100%;
				left: 0;
				top: 0;
			} // Content transitions for Drawer
			&.Overlay--transition-hide {
				.Overlay__contentWrapper {
					transform: translateX(-100%);
				}
			}
		}

		.Overlay__contentWrapper__closeButton {
			--overlayContentWrapperCloseButtonSize: 64px;
			background: transparent;
			border: 0;
			font-size: 21px;
			height: var(--overlayContentWrapperCloseButtonSize);
			line-height: var(--overlayContentWrapperCloseButtonSize);
			outline-color: var(--blue);
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: var(--overlayContentWrapperCloseButtonSize);
			z-index: 3;
			@include media(desktop) {
				padding: 0 1.5rem;
				width: auto;

				&::before {
					content: 'Close';
					font-size: 1.6rem;
					margin-right: 0.75em;
					text-transform: uppercase;
				}
			}
		}
	}

	&--Modal {
		align-items: start;

		.Overlay__contentWrapper {
			height: 100%;
			overflow-y: auto;
			width: 100%;

			// Modal is full screen under screen width 768px
			@include media(desktop) {
				--overlayContentWrapperMarginHorizontal: 40px;
				--overlayContentWrapperMarginVertical: 40px;
				height: auto;
				margin: var(--overlayContentWrapperMarginVertical) var(--overlayContentWrapperMarginHorizontal);
				max-height: calc(100% - (2 * var(--overlayContentWrapperMarginVertical)));
				max-width: calc(100% - (2 * var(--overlayContentWrapperMarginHorizontal)));
				min-width: 688px;
				overflow-x: hidden;
				width: auto;
			}
		}

		.Overlay__contentWrapper__closeButtonContainer {
			background: transparent;
			padding: 1.5rem 1rem;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;

			&--grey {
				background: #f1f1f1;
				@include media(desktop) {
					background: transparent;
				}
			}
		}

		.Overlay__contentWrapper__closeButton {
			--overlayContentWrapperCloseButtonSize: 64px;
			background: #fff;
			border: 1px solid #555;
			color: #555;
			cursor: pointer;
			float: right;
			font-size: 1.5rem;
			margin-right: 2rem;
			padding: 7px 13px;
			@include media(desktop) {
				background: transparent;
				border: 0;
				font-family: var(--fontHeading);
				font-size: 2.1rem;
				height: 28.5px;
				line-height: normal;
				outline-color: var(--blue);
				padding: 1px 9px 6px;
				position: absolute;
				right: 0;
				top: 0;
				width: auto;

				.lpIcon-close02 {
					font-size: 1.3rem;
					font-weight: 500;
				}
			}
			@include media(mobile) {
				border-radius: 5px;
			}

			&::before {
				content: 'Close';
				font-size: 1.6rem;
				text-transform: uppercase;
				@include media(desktop) {
					color: #000;
					margin-right: 0.5rem;
				}
			}

			.lpIcon-close02 {
				display: none;
				@include media(desktop) {
					color: #000;
					display: inline-block;
					font-size: 1.5rem;
				}
			}
		}
	}
}
