@import '../../style/mixins';

.Button {
	background: #900;
	border: 1px solid transparent;
	border-radius: 5px;
	color: #fff;
	cursor: pointer;
	display: inline-block;
	outline-color: var(--blue);
	outline-offset: 3px;
	padding: 0.5em 0.25em;
	position: relative;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;

	@include media(mobile) {
		border-radius: 5px;
	}

	&:hover,
	&--loading {
		background: #d00;
		color: #fff;
	}

	&--primary {
		&:hover,
		&.Button--loading {
			background: #d00;
		}
	}

	&--secondary {
		background: var(--darkGrey);
		border-color: var(--darkGrey);
		color: #fff;

		&:hover,
		&.Button--loading {
			background: var(--darkGrey);
		}
	}

	&--tertiary {
		background: transparent;
		border-color: var(--darkGrey);
		color: var(--darkGrey);

		&:hover,
		&.Button--loading {
			background: transparent;
			border-color: #000;
			color: var(--darkGrey);
		}
	}

	&--white {
		background: #fff;
		border-color: var(--darkGrey);
		color: var(--darkGrey);

		&:hover,
		&.Button--loading {
			background: transparent;
			border-color: #000;
			color: var(--darkGrey);
		}
	}

	&--link {
		background: transparent;
		border: 0;
		color: var(--blue);
		font-family: var(--fontBody);
		padding: 0;
		text-decoration: underline;
		text-transform: none;

		@include media(desktop) {
			color: var(--baseTextColor);
		}

		&:hover {
			color: var(--red);
		}

		&:hover,
		&.Button--loading {
			background: transparent;

			.Button__loadingIcon {
				display: none;
			}
		}

		.Button__label {
			padding: 0;
		}
	}

	&--none {
		appearance: none;
		background: transparent;
		border: 0;
		color: var(--baseTextColor);
		font-size: inherit;
		text-transform: none;

		&:hover {
			background: transparent;
			color: var(--baseTextColor);
		}
	}

	&--large {
		font-size: 1.6rem;
		padding: 0.7em 1.25em;
	}

	// TODO: add small size class

	&[aria-disabled='true']:not(&--link):not(&--none),
	&[aria-disabled='true']:not(&--link):not(&--none):hover {
		background: #ccc;
	}

	&[aria-disabled='true'],
	&[aria-disabled='true']:hover {
		border-color: transparent;
		color: var(--darkGrey);
		cursor: default;
	}

	&__label {
		padding: 0 1.25em;
		white-space: nowrap;
	}

	&__loadingIcon {
		height: 0.75em;
		line-height: 1;
		margin: 0.125em -0.875em 0.125em 0.125em;
		text-align: center;
		width: 0.75em;

		&::before {
			animation: spin 1s linear infinite;
			content: '\e960';
			display: inline-block;
			font-family: 'LPIcons' !important;
			font-size: 0.75em;
			transform-origin: center center;
		}
	}
}

// adjustments for buttons that are <a> tags
a.Button {
	padding-left: 1.5em;
	padding-right: 1.5em;
}
