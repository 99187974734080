.SortFilterThumbnail {
	margin-right: 8px;
	vertical-align: middle;

	&__attrBodyImage {
		border: 0;
		border-radius: 0;
		display: block;
		margin: 0 auto 20px;
	}
}