@import '../SortFilterAttributeGridVars';

.SortFilterAttributeMultiValueList {
	--left-margin: 30px;
	--vertical-margin: 8px;

	.fieldGroup {
		flex-direction: column;
	}

	.fieldCheckbox {
		clear: none;

		.SortFilterNestedDrawer & {
			padding: 10px 0;
		}

		.SortFilterDropdownContent & {
			padding: 8px 0;
		}

		&.field label {
			display: block;
			float: none;
			font-size: 14px;
			padding-bottom: 1px;
			padding-right: 0;

			.SortFilterNestedDrawer & {
				padding-top: 2px;
			}

			.SortFilterDropdownContent & {
				padding-top: 1px;
			}

			&::before,
			&::after {
				top: calc(50% - 11px);
			}
		}
	}

	.SortFilterAttrGroupGridView.SortFilterNestedDrawer & {
		.SortFilterAttributeValue {
			font-size: 16px;
		}
	}

	.SortFilterAttrGroupGridView.SortFilterDropdownContent & {
		.SortFilterAttributeValue {
			font-size: 13px;
		}
	}

	.SortFilterAttrGroupGridView & {
		.fieldGroup {
			display: grid;
			grid-gap: var(--gridRowGap) var(--gridColumnGap);
			grid-template-columns: repeat(var(--gridColumnCount), 1fr);
			justify-content: space-between;

			&:nth-child(2) {
				margin-top: 30px;

				.fieldCheckbox {
					align-items: flex-end;
					display: flex;
					justify-content: center;

					label {

						&::after,
						&::before {
							top: 0;
						}
					}

					.SortFilterAttributeValue__name {
						min-height: 50px;

						@media only screen and (max-width: 767px) {
							min-height: 60px;
						}
					}
				}
			}
		}

		.fieldCheckbox {
			box-sizing: content-box;
			margin: 0 auto;
			padding: 0;
			width: var(--gridCellWidth);

			label {
				float: none;
				padding: 0;
				text-align: center;

				&::before {
					left: 50%;
					margin-left: -11px;
					top: var(--gridCheckboxTop);
				}

				&::after {
					left: 50%;
					margin-left: -6px;
					top: var(--gridCheckboxTop);
				}
			}
		}

		.SortFilterAttributeValue {
			display: block;
			line-height: 1.3em;
			margin-top: 33px;
		}

		.SortFilterThumbnail {
			border: var(--thumbnailBorderWidth) solid #ccc;
			box-sizing: border-box;
			display: block;
			height: var(--gridImageHeight);
			margin: auto;
			width: var(--gridImageWidth);
		}
	}

	.SortFilterDropdownContent--multiColumn & {
		.fieldGroup {
			display: grid;
			grid-auto-flow: column;
			grid-gap: 0 10px;
			grid-template-rows: repeat(var(--numberOfRows), auto);
		}
	}

	.SortFilterDropdownContent--multiColumnOneColumn & {
		.fieldGroup {
			display: block;
		}
	}

	.SortFilterDropdownContent--multiColumnThreeColumns & {
		.fieldGroup .fieldCheckbox {
			align-items: center;
			display: flex;
			padding: 5px 0;
		}
	}

	.valueGroupHeader {
		align-items: center;
		display: flex;
		padding: 10px 0 5px;

		.SortFilterNestedDrawer & {
			font-size: 18px;
			font-weight: 500;
		}

		&--indented {
			margin-left: var(--left-margin);
		}
	}

	.valueGroupBodyImage {
		margin-bottom: var(--vertical-margin);
		max-width: fit-content;

		&--indented {
			margin-left: var(--left-margin);
		}
	}

	.valueGroup {
		margin-left: var(--left-margin);
		width: auto;

		.SortFilterNestedDrawer & .SortFilterAttributeValue {
			font-weight: normal;
		}
	}

	&__separator {
		border-top: 1px solid var(--baseBorderColor);
		margin: var(--vertical-margin) 0;
		width: 100%;
	}
}

.SortFilterAttrGroupGridView {
	padding: var(--gridContainerPadding);

	&.SortFilterNestedDrawer {
		padding: 0;
		width: 100%;
	}
}

// IE11 Support
_:-ms-fullscreen,
:root .SortFilterAttrGroupGridView .SortFilterAttributeMultiValueList .fieldGroup {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}