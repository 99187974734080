@import '../../../../../Content/global/searchMixins';
.SortByDropdown {
	.SortByDropdown__button {
		align-items: center;
		background-color: #fff;
		border: 1px solid #0b0a0a;
		border-radius: 5px;
		box-sizing: content-box;
		color: #333;
		display: inline-flex;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: 20px;
		margin: 0;
		height: 24px;
		padding: 5px 18px;
		text-decoration: none;
		text-transform: none;
		transition: background-color 200ms ease 200ms, border 200ms ease 200ms, color 200ms ease 200ms, margin 200ms ease 200ms;
		white-space: nowrap;

		&:hover,
		&:focus,
		&.Button--active {
			background-color: #ccc;
			border-color: #ccc;
			color: #000;
			transition: background-color 200ms ease, border 200ms ease, color 200ms ease, margin 200ms ease;
		}

		.Button__label {
			display: flex;
			padding: 0;
		}
	}

	&__icon {
		margin-right: 5px;
		max-width: 20px;
	}

	&__wrapper {
		--dropdown-content-padding-left-right: 10px;
		--dropdown-content-padding-top-bottom: 7px;
		padding: 7px 10px;
		width: 220px;
	}

	&__option {
		display: block;
		padding: 8px 10px;
		color: #000;

		&:link,
		&:visited {
			color: #000;
			text-decoration: none;
		}

		&--selected,
		&:hover,
		&:focus {
			background: #f1f1f1;
			color: #000;
		}

		&--selected {
			font-weight: bold;
		}
	}
}
