@import '../../../../style/mixins';

.SortFilterSliderTwoThumb {
	&__flexWrapper {
		display: flex;
		flex-direction: column;

		.SortFilterAttrGroupDropdown & {
			margin-top: 20px;
		}
	}

	&__formWrapper {
		align-items: center;
		display: flex;
		font-size: 1.5rem;
		justify-content: center;
		margin-bottom: 36px;

		.SortFilterAttrGroupDropdown & {
			margin: 5px 0 16px;
		}

		label {
			height: 1px;
			left: -1000px;
			overflow: hidden;
			position: absolute;
			width: 1px;
		}

		.field {
			padding: 0;

			// TODO: when .lpForm no longer used, this selector can be simplified
			input[type='text'] {
				border: 1px solid #999;
				box-sizing: border-box;
				float: left;
				font-size: 20px;
				height: 45px;
				margin: 0 13px;
				text-align: center;
				width: 68px;

				.SortFilterAttrGroupDropdown & {
					font-size: 1.4rem;
					height: 34px;
					margin: 0 5px;
					width: 50px;
				}
			}
		}

		span {
			font-family: var(--fontHeading);
			font-size: 2.2rem;
			line-height: 35px;
			margin-right: 4px;

			@include media(desktop) {
				font-size: inherit;
			}
		}
	}

	&__toLabel {
		margin-left: 4px;
	}

	// general desktop styles
	.SortFilterAttrGroupDropdown & {
		.RangeMinMaxLabels {
			margin-bottom: 16px;
		}

		.Button {
			display: block;
			margin: 10px auto 0;
			max-width: 160px;
			width: 100%;
		}
	}

	.SortFilterNestedDrawer & {
		.Button {
			display: block;
			margin: 15px auto 25px;
			max-width: 260px;
			width: 100%;
		}
	}

	.RangeSlider--raiseMinThumb {
		.RangeSlider__thumb-wrapper:first-child {
			z-index: 3 !important;
		}
	}

	.SortFilterAttributeValueList {
		border-top: 1px solid #ccc;
		margin-top: 28px;
		padding-top: 15px;
	}
}
