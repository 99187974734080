@use 'sass:math';

@import '../../../_client/style/mixins';
@import '../style-guide';
@import '../utilities.scss';

@mixin ratio-border-width {
	@media (-webkit-min-device-pixel-ratio: 2) {
		& {
			border-width: 0.5px;
		}
	}
	@media (-webkit-min-device-pixel-ratio: 3) {
		& {
			border-width: 1px;
		}
	}
}

label {
	@include clearfix;
	display: block;
	font-size: 16px;
	font-weight: normal;
	line-height: 20px;

	&.required::after {
		color: $lpRed;
		content: '*';
		display: inline;
		font-size: 1.125em;
		font-weight: 500;
		padding-left: 5px;
	}
}

input,
select,
textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	border-radius: 5px;
}

input:not([type='checkbox'], [type='radio']),
select,
textarea {
	@include media(mobile) {
		border-radius: 5px;
	}
	&:focus {
		outline-offset: calc(-1 * var(--fieldHighlightWidth));
	}
}

[role*='dialog'] {
	*[tabindex]:focus,
	a:focus,
	button:focus {
		outline: 1px solid var(--fieldHighlightColor);
	}
}

textarea {
	background-color: $fieldBackgroundColor;
	border: 1px solid $fieldBorderColor;
	border-radius: 5px;
	box-sizing: border-box;
	display: block;
	padding: 10px;
	resize: vertical;
	width: 100%;
}

input:not([type='checkbox'], [type='radio']),
select,
.lpSelectMobileDrawer {
	@include ratio-border-width;
	background-color: $fieldBackgroundColor;
	border: 1px solid $fieldBorderColor;
	box-sizing: border-box;
	font-size: 18px;
	height: $fieldHeight;
	line-height: 20px;
	padding: 14px 10px;
	width: 100%;

	&.adjacentButton {
		height: 44px;
		padding: 8px 10px;
	}

	.fieldComposite & {
		border: 0;
		height: $fieldHeight - 2;
	}
}

input.qty {
	padding: 8px 0;
	text-align: center;
	width: 44px;
}

select.qty {
	padding-left: 5px;
	text-align: left;
}

// The appearance of a single field with extra elements (e.g. Show password toggle)
.fieldComposite {
	@include ratio-border-width;
	background: $fieldBackgroundColor;
	border: 1px solid $fieldBorderColor;
	box-sizing: border-box;
	border-radius: 5px;
	clear: both;
	display: flex;
	height: $fieldHeight;

	&.fcdSelectCaret {
		background: $fieldBackgroundColor;
		position: relative;

		select {
			font-size: 16px;
		}

		.fieldCompositeDetail {
			border: 0;
			height: 100%;
			margin: 0;
			pointer-events: none;
			position: absolute;
			right: 0;
			top: 0;
			width: 50px;
		}

		.caret.right {
			top: 58%;
		}
	}
}

.fieldCompositeDetail {
	@include retinaBorderWidthHalfPixel;
	border-left: 1px solid $fieldBorderColor;
	box-sizing: border-box;
	color: $fieldHelpTextColor;
	font-size: 13px;
	font-weight: normal;
	line-height: 40px;
	margin: 5px 0;
	padding: 0 8px;

	.caret {
		margin-top: -8px;
		position: absolute;
		right: 20px;
		top: 50%;
	}
}

.fieldCheckbox {
	@include clearfix;
	width: 100%;

	&.field > label {
		padding-bottom: 1px;
		padding-top: 2px;
	}

	> label {
		padding: 10px 10px 10px 30px;
		position: relative;

		&::after,
		&::before {
			box-shadow: none;
			box-sizing: border-box;
			content: '';
			position: absolute;
			transition: all $elementAnimationDuration $elementTimingFunction;
		}
		// Checkmark
		&::after {
			border-bottom: 3px solid #fff;
			border-left: 3px solid #fff;
			height: 8px;
			left: 5px;
			opacity: 0;
			top: 0;
			transform: translateY(70%) rotate(-45deg);
			transform-origin: center center;
			width: 12px;
		}
		// Checkmark Box
		&::before {
			@include retinaBorderWidthHalfPixel;
			background-color: #fff;
			border: 1px solid $checkboxfieldBorderColor;
			border-radius: 4px;
			height: 22px;
			left: 0;
			top: 0;
			width: 22px;
		}
	}

	input[type='checkbox'] {
		border: 0;
		display: block !important;
		height: 0;
		margin: 0;
		overflow: hidden;
		width: 0;
	}
	// Checked State
	input[type='checkbox']:checked + label,
	// Support cshtml strangeness providing a hidden element
	input[type='checkbox']:checked + input[type='hidden'] + label {
		// Checkmark
		&::after {
			opacity: 1;
		}
		// Checkmark Box
		&::before {
			background-color: $checkboxBackgroundColorChecked;
			border-color: $checkboxBorderColorChecked;
		}
	}

	input[disabled] {
		+ label {
			color: #aaa;
			cursor: default;

			&::before {
				border-color: #ccc !important;
				cursor: default;
			}

			&::after {
				cursor: default;
			}
		}

		&:checked + label::before {
			background-color: #ccc;
		}
	}
}

.fieldRadio {
	width: 100%;

	&.field > label {
		padding-bottom: 0;
		padding-top: 0;
	}

	> label {
		padding: 10px 10px 10px 30px;
		position: relative;

		&::after,
		&::before {
			box-shadow: none;
			box-sizing: border-box;
			content: '';
			position: absolute;
			transition: all $elementAnimationDuration $elementTimingFunction;
		}
		// Checkmark
		&::after {
			background-color: $radioBackgroundColorChecked;
			border: 5px solid #fff;
			border-radius: 50%;
			height: 20px;
			left: 0;
			opacity: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 20px;
		}
		// Checkmark Box
		&::before {
			@include retinaBorderWidthHalfPixel;
			border: 1px solid $fieldBorderColor;
			border-radius: 50%;
			height: 20px;
			left: 0;
			margin-top: -10px;
			top: 50%;
			width: 20px;
			z-index: 1;
		}
	}

	input[type='radio'] {
		border: 0;
		display: block !important;
		height: 0;
		margin: 0;
		overflow: hidden;
		width: 0;
	}
	// Checked State
	input[type='radio']:checked + label,
	// Support cshtml strangeness providing a hidden element
	input[type='radio']:checked + input[type='hidden'] + label {
		// Checkmark
		&::after {
			opacity: 1;
		}
		// Checkmark Box
		&::before {
			border: 2px solid $radioBorderColorChecked;
		}
	}

	input[disabled] + label,
	input[disabled]:checked + label {
		color: #aaa;

		&::before {
			border-color: #ccc;
		}
	}

	input[disabled]:checked + label {
		&::after {
			background-color: #ccc;
		}
	}
}

.field {
	box-sizing: border-box;
	clear: both;
	margin: 0;
	padding-bottom: 16px;

	&.fieldHalf {
		width: calc((50%) - (10px));
	}

	&.fieldNoLabel {
		padding-top: 21px;
	}

	&.fieldInline {
		display: inline-block;
		margin-right: 30px;
		width: auto;

		&.hidden {
			display: none;
		}

		label {
			margin-bottom: 0;

			.fieldGroup & {
				margin-right: 0;
			}
		}
	}

	.requiredLabel {
		padding-right: 8px;
		position: relative;

		&::after {
			color: #900;
			content: '\002A'; //asterisk character
			display: inline;
			font-size: 18px;
			font-weight: 500;
			margin-left: 1px;
			position: absolute;
		}
	}

	label {
		float: left;
	}

	+ * {
		clear: both;
	}
}

.fieldHelper {
	box-sizing: border-box;
	line-height: $fieldHeight;
	margin: 0 0 15px;

	&.noLabel {
		margin-top: 20px;
	}

	.calloutBtn {
		vertical-align: top;
	}
}

.fieldGroup {
	clear: both;
	display: flex;
	flex-wrap: wrap;
}

.fieldPair {
	@include clearfix;
	clear: both;

	.field,
	.fieldHelper {
		clear: none;
		float: left;
		width: 50%;

		&:first-child {
			padding-right: 10px;
		}
	}

	.field {
		&.field65 {
			width: 65%;
		}

		&.field35 {
			width: 35%;
		}
	}
}

.fieldPairInline {
	display: flex;

	.grow {
		width: 100%;
	}

	.field,
	.fieldHelper {
		&:first-child {
			padding-right: 10px;
		}
	}
}

.hintText {
	color: $fieldHelpTextColor;
	float: right;
	font-size: 12px;
	font-weight: normal;
}

.validationMessage {
	position: relative;
	top: 0;
}

.validationInputGroup {
	margin-bottom: 15px;

	&__header {
		font-weight: 500;
		margin-bottom: 10px;
	}

	.field {
		margin-bottom: 0;
	}
}

.required-footnote {
	color: $lpRed;
	font-size: 0.9rem;
	margin-top: 12px;

	&::before {
		content: '*';
		font-size: 1.25em;
		font-weight: 500;
		padding-right: 4px;
		position: relative;
		top: 3px;
	}

	&::after {
		content: 'Required';
	}
}

.requiredNote {
	color: #900;
	margin: 1em 0;
	text-align: right;

	&--alignedLeft {
		text-align: left;
	}
}

.requiredNoteInline {
	margin-bottom: -45px;
}

.server-error {
	background: $errorBackgroundColor;
	border: 2px solid $errorBorderColor;
	color: $errorTextColor;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.2em;
	margin: 1em 0;
	padding: 10px;

	// ValidationSummary() creates div > ul container
	ul {
		margin: 0;
	}

	li {
		margin: 0 0 8px 20px;

		&:last-child {
			margin-bottom: 0;
		}

		&:only-child {
			list-style: none;
			margin: 0;
		}
	}
}

.clearableInput {
	$clearableInputButtonSize: 22px;
	$clearableInputButtonSizeOffset: 6px;

	&__input {
		padding-right: $clearableInputButtonSize + (2 * $clearableInputButtonSizeOffset) !important;

		+ .fieldCompositeDetail {
			border-left: 0;
			padding: 0;
			position: relative;
		}
	}

	&__button {
		background: transparent;
		border: 0;
		color: #565656;
		font-size: $clearableInputButtonSize;
		margin-top: math.div(-$clearableInputButtonSize, 2);
		padding: 0;
		pointer-events: auto;
		position: absolute;
		right: $clearableInputButtonSizeOffset;
		top: 50%;
	}
}
