.CollapsibleDisclosure {
	background: transparent;
	border: 0;
	color: inherit;
	cursor: pointer;
	display: block;
	font-size: inherit;
	margin: 0;
	outline-color: var(--blue);
	padding: 0;
	text-align: left;
	width: 100%;
}
