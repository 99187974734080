.CollapsibleTitle {
	background: transparent;
	box-sizing: border-box;
	display: flex;
	font-size: 18px;
	font-weight: 500;
	line-height: 1.15;
	padding: 1rem 2.75rem 1rem 1rem;
	position: relative;

	&__caret {
		background: transparent;
		border: 0;
		display: block;
		font-size: 1.8rem;
		height: 2.75rem;
		padding: 0;
		position: absolute;
		right: 17px;
		text-indent: -9999px;
		top: 50%;
		transform: translateY(-50%);
		width: 1.5em;

		&::before {
			border-left: 2px solid var(--darkGrey);
			border-top: 2px solid var(--darkGrey);
			content: '';
			display: block;
			height: 0.58em;
			position: absolute;
			right: 50%;
			top: 50%;
			transform: translate(50%, -75%) rotateX(0deg) rotateZ(-135deg);
			transform-origin: center center;
			transition: transform var(--surfaceTransitionDuration) var(--surfaceTimingFunction);
			width: 0.58em;
		}

		&--open::before {
			transform: translate(50%, -25%) rotateX(180deg) rotateZ(-135deg);
		}
	}
}
