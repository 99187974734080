.SortFilterAttrGroupDropdown {
	--footer-height: 60px;

	display: inline-block;
	line-height: 20px;
	padding-bottom: 2px;

	&--hidden {
		display: none;
	}
}
