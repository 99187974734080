.sortSearchInput {
	align-items: center;
	display: flex;
	margin: 0 0 10px;

	label {
		margin-right: 12px;
	}

	input[type="text"] {
		box-sizing: border-box;
		font-size: 1.5rem;
		height: 40px;
		width: 100%;

		.SortFilterNestedDrawer & {
			font-size: 2rem;
		}
	}
}

.sortSearchNoResults {
	padding: 5px 0;
}
