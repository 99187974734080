@use 'sass:math';

// Mixin
@mixin clearfix {
	&::after {
		clear: both;
		content: '';
		display: table;
	}
}

@mixin retinaBorderWidthHalfPixel {
	@media (-webkit-min-device-pixel-ratio: 2) {
		& {
			border-width: 0.5px;
		}
	}
	@media (-webkit-min-device-pixel-ratio: 3) {
		& {
			border-width: 1px;
		}
	}
}

@mixin caret($size: 8px, $thickness: 1px, $color: #000, $disabledColor: #ccc) {
	border-bottom: $thickness solid $color;
	border-right: $thickness solid $color;
	height: $size;
	width: $size;

	&.disabled {
		border-color: $disabledColor;
		cursor: default;
	}
}

@mixin checkmark($checkColor: #3ba736) {
	border: solid $checkColor;
	border-width: 0 5px 5px 0;
	content: '';
	height: 16px;
	left: 20px;
	margin-top: -12px;
	position: absolute;
	top: 50%;
	transform: rotate(45deg);
	width: 7px;
}

@mixin closeX($boxSize: 25px, $thickness: 2px, $color: $closeXColor, $activeColor: $closeXColorActive, $padding: 5px) {
	background: none;
	border: 0;
	display: block;
	height: $boxSize;
	outline-color: $fieldHighlightColor;
	padding: $padding;
	position: relative;
	width: $boxSize;

	&::before,
	&::after {
		background-color: $color;
		content: '';
		display: block;
		height: ($boxSize * 1.363636);
		left: math.div($boxSize + $thickness + $padding, 2);
		position: absolute;
		right: auto;
		top: math.div($boxSize - $boxSize * 1.363636 + $padding, 2);
		transform-origin: center center;
		transition: background-color $elementAnimationDuration $elementTimingFunction;
		width: $thickness;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}

	&:active,
	&:focus {
		&::before,
		&::after {
			background-color: $activeColor;
		}
	}
}

@mixin arrowPsuedoHelperMixin($size, $color, $direction) {
	border-right: $size solid $color;
	border-top: $size solid $color;
	box-sizing: border-box;
	content: '';
	display: block;
	height: 4 * $size;
	width: 4 * $size;
	@if $direction == 'right' {
		transform: rotate(45deg);
	} @else if $direction == 'left' {
		transform: rotate(-135deg);
	} @else {
		transform: rotate($direction);
	}
}

@mixin arrow($size, $color, $direction, $createPsuedo: true) {
	// should create a psuedo element, or current element is a psuedo element
	@if $createPsuedo == true {
		height: 4 * $size;
		width: 4 * $size;

		&::after {
			@include arrowPsuedoHelperMixin($size, $color, $direction);
		}
	} @else {
		@include arrowPsuedoHelperMixin($size, $color, $direction);
	}
}

@mixin wideCaret($size, $color, $direction) {
	&::before {
		border: 1px solid $color;
		content: '';
		display: block;
		height: 0;
		left: 0;
		margin: 0;
		opacity: 1;
		top: 32px;
		width: $size;
		@if $direction == 'right' {
			transform: rotate(118deg);
		} @else if $direction == 'left' {
			transform: rotate(62deg);
		}

		else {
			transform: rotate($direction);
		}
	}

	&::after {
		border: 1px solid $color;
		content: '';
		display: block;
		margin: 0;
		opacity: 1;
		position: absolute;
		top: 4px;
		width: $size;
		@if $direction == 'right' {
			transform: rotate(-118deg);
		} @else if $direction == 'left' {
			transform: rotate(-62deg);
		} @else {
			transform: rotate($direction);
		}
	}
}

// breakpoint mixin for smaller screens
@mixin smallScreenBP($maxWidth: 320px) {
	@media only screen and (max-width: $maxWidth) {
		@content;
	}
}

@mixin circlePlusIcon(
	$circleDiameter: 40px,
	$plusSize: 20px,
	$plusThickness: 3px,
	$borderSize: 3px,
	$borderColor: #565656,
	$backgroundColor: transparent
) {
	background: $backgroundColor;
	border: $borderSize solid $borderColor;
	border-radius: 100%;
	display: inline-block;
	height: $circleDiameter;
	position: relative;
	width: $circleDiameter;

	&::before {
		background: $borderColor;
		content: '';
		height: $plusThickness;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $plusSize;
	}

	&::after {
		background: $borderColor;
		content: '';
		height: $plusSize;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);
		width: $plusThickness;
	}
}
