.SortFilterDropdownFooter {
	align-items: center;
	display: flex;
	height: var(--footer-height);
	justify-content: center;

	.Button {
		border-radius: 5px;
		font-size: 1.15rem;
		height: 29px;
		line-height: 1.3rem;
		margin-right: 8px;
		padding: 0;
		text-transform: uppercase;
		width: 100px;

		&:last-child {
			margin-right: 0;
		}

		&__loadingIcon {
			margin-right: -0.575em;
			padding-right: 3px;
			vertical-align: top;
		}

		&__label {
			padding: 0 1em;
		}
	}
}
