$baseFontColor: #333;
$baseFontSize: 16px;
$baseFontLineHeight: 1.5; // unitless (em), so that changes to font-size will give a proportionate line-height
$baseSmallFontSize: 11px;

$textColor: #000;
$lpRed: #c00;
$lpBlue: #0066bb;
$errorRed: #c00;

$backgroundColor: #fff;
$backgroundColorAlt1: #f1f1f1;
$backgroundColorAlt3: #ddd;
$backgroundColorAlt4: #ccc;
$backgroundColorAlt4: #bbb;
$backgroundColorAlt5: #898989;
$fieldHighlightColorCheckboxRadio: #38f;

$backgroundOverlay: rgba(0,0,0,.65);
$backgroundOverlayContent: #fff;

$backgroundScrim: rgba(0,0,0,.35);
$backgroundScrimWhite: rgba(255,255,255,.65);

$shadowDrawerBottom: 0 -3px 10px rgba(0,0,0,.23);
$shadowDrawerTop: 0 3px 10px rgba(0,0,0,.23);
$shadowStickyHeader: 0 16px 30px 0 rgba(0, 0, 0, .15);

$horizontalRuleColor: #bbb;
$imageBorderColor: #ccc;
$imageBorderColorSelected: #000;

$fieldHelpTextColor: #565656;
$fieldBackgroundColor: #fff;
$fieldBorderColor: #999;
$fieldBorderColorAlt1: #ccc;
$fieldValidationMessageError: $lpRed;
$fieldHighlightColor: $lpBlue;
$fieldHighlightWidth: 2px;
$fieldHighlightColorInvalid: $lpRed;
$fieldHeight: 47px;

$errorBackgroundColor: #ffebeb;
$errorBorderColor: $lpRed;
$errorTextColor: $lpRed;

$checkboxfieldBorderColor: #595959;
$checkboxBorderColorChecked: $lpBlue;
$checkboxBackgroundColorChecked: $lpBlue;

$radioBorderColorChecked: $lpBlue;
$radioBackgroundColorChecked: $lpBlue;

$blueLink: $lpBlue;

$closeXColor: #999;
$closeXColorActive: $lpRed;

// Use indentation to describe structural z-index layers

// z-index definitions relative to <body>
$zPage: 1;
$zGlobalHeader: 3;
$zGlobalMenuSearchOpen: 2;
	$zGlobalSearch: 1;
	$zGlobalShortCuts: 2;
$zMobileMenu: 4;
	$zIndexMenuScrim: 1;
	$zIndexSubMenu: 2;
	$zIndexMenuCaretMask: 3;
		$zIndexMenuCaretMaskCaret: 1;
		$zIndexMenuCaretMaskLeftRight: 2;
$zOverlay: 7;
	$zOverlayIframe: 1;
	$zOverlayClose: 2;
$zDrawer: 7;
	$zDrawerContainer: 1;
		$zDrawerClose: 2;
// Keep loading overlay as highest general use global z-index
$zLoadingOverlay: 10;
$zLoadingOverlayMenuOpen: 2;

// z-index definitions relative to parent
$zCalloutBtnLoading: 1;
$zCalloutBtnText: 2;

$zProductItemIssueBorder: 2;

// Transitions, Animations and Timing standardization
// Large components: movement, fade in/out, etc
$surfaceTimingFunction: cubic-bezier(.55, 0, .1, 1); // Google Material Swift Out
$surfaceAnimationDuration: 320ms;

// Target element: color transitions mostly
$elementTimingFunction: ease-in-out;
$elementAnimationDuration: 250ms;
