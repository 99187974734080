.SortFilterDisplaySetDrawer {
	.OverlayContent {
		background: var(--lightGrey);

		main {
			max-height: none;
			padding: 0 0 20px;
		}
	}

	&__buttonGroup .Button {
		// redundant but needed to resolve specificity issue due to ordering of CSS rules during bundling process
		&:hover {
			background: var(--lightGrey);
		}

		&__label {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 0 5px;
		}

		.caret {
			border-width: 2px;
			float: none;
			height: 8px;
			position: relative;
			right: 20px;
			width: 8px;
		}
	}

	// Shared styling for drawer disclosure buttons and direct link attribute groups
	&__buttonGroup .Button,
	.attrGroupNoDrawer {
		background: var(--lightGrey);
		border: 0;
		color: #000;
		display: block;
		font-size: 18px;
		line-height: 33px;
		outline-offset: -1px;
		padding: 0.61em 0.25em;
		position: relative;
		text-align: left;
		text-transform: none;
		width: 100%;

		&::after {
			background: #bbb;
			bottom: 0px;
			content: '';
			display: block;
			height: 1px;
			left: 10px;
			margin: 0 auto;
			position: absolute;
			width: calc(100% - 20px);
		} 
	}

	.attrGroupNoDrawer {
		box-sizing: border-box;
		// matches the combined padding of the regular drawer disclosure button and it's inner span
		padding-left: calc(0.25em + 5px);
		padding-right: calc(0.25em + 5px);
		text-decoration: none;
	}

	&__header--withBorder {
		border-bottom: 8px solid #fff;
	}
}
