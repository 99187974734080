.SortFilterSliderOneThumb {
	&__inputWrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		margin-bottom: 42px;

		input {
			margin-right: 14px;
			text-align: center;
			width: 74px;

			.SortFilterAttrGroupDropdown & {
				margin-right: 7px;
				width: 40px;
			}
		}

		label {
			font-size: 21px;

			.SortFilterAttrGroupDropdown & {
				font-size: 1.3rem;
			}
		}

		.SortFilterAttrGroupDropdown & {
			grid-column: 1 / 2;
			grid-row: 2 / 3;
			margin-bottom: 0;
		}
	}

	.SortFilterAttrGroupDropdown & {
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-rows: auto auto auto;

		&__sliderWrapper {
			grid-column: 1 / 3;
			grid-row: 1 / 2;
			margin-top: 20px;
		}

		.RangeMinMaxLabels {
			margin-bottom: 6px;
		}

		.Button {
			grid-column: 2 / 3;
			grid-row: 2 / 3;
			margin: 15px auto;
			width: 85%;
		}
	}

	.SortFilterNestedDrawer & {
		.Button {
			display: block;
			margin: 15px auto 25px;
			max-width: 260px;
			width: 100%
		}
	}

	.SortFilterAttributeValueList {
		border-top: 1px solid #ccc;
		grid-column: 1 / 3;
		margin-top: 28px;
		padding-top: 15px;
	}
}
