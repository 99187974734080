.SortFilterDisplaySetDropdowns {
	--horizontalBtnMargins: 0 12px 10px 0;

	// Shared Button styling for SortFilterAttrGroupDropdown and SortFilterAttrGroupNoDropdown
	.attributeGroupBtn {
		align-items: center;
		background-color: #0b0a0a;
		border: 1px solid #0b0a0a;
		border-radius: 5px;
		box-sizing: content-box;
		color: #fff;
		display: inline-flex;
		font-size: 1.5rem;
		font-weight: 500;
		height: 24px;
		line-height: 20px;
		margin: 0;
		padding: 5px 18px;
		text-decoration: none;
		text-transform: none;
		transition: background-color 200ms ease 200ms, border 200ms ease 200ms, color 200ms ease 200ms,
			margin 200ms ease 200ms;
		white-space: nowrap;

		@media only screen and (max-width: 1200px) {
			padding: 5px 13px;
		}

		.Button__label {
			align-items: center;
			display: flex;
			padding: 0;
		}

		.caret {
			border-bottom: 2px solid #fff;
			border-right: 2px solid #fff;
			display: inline-block;
			height: 4px;
			margin-left: 5px;
			transition: border-color 0.1s ease 200ms;
			width: 4px;

			&.down {
				margin-top: 3px;
				transform: rotate(45deg);
			}
		}

		&--white {
			background-color: #fff;
			color: #333;

			.caret {
				border-color: #333;
			}
		}

		&:hover,
		&:focus,
		&.Button--active {
			background-color: #ccc;
			border-color: #ccc;
			color: #000;
			transition: background-color 200ms ease, border 200ms ease, color 200ms ease, margin 200ms ease;

			.caret {
				border-color: #000;
				transition-delay: 1ms;
			}
		}

		&.Button--active {
			& + .Dropdown {
				margin-top: -2px;
			}
		}

		// straightens the border out on the bottom on hover. We don't want to do this for links like pros specials
		&:not(.attributeGroupBtn--noDropdown) {
			&[aria-expanded='true'] {
				&:focus,
				&.Button--active {
					border-radius: 5px 5px 0 0;
				}
			}
		}

		&.attributeGroupBtn--noDropdown {
			&.attributeGroupBtn--imgOnly .SortFilterThumbnail {
				margin-right: 0;
			}
		}
	}

	div.Dropdown {
		z-index: 3;
	}

	.SortFilterAttrGroupDropdown,
	.attributeGroupBtn--noDropdown {
		margin: var(--horizontalBtnMargins);

		vertical-align: top;

		@media only screen and (max-width: 1200px) {
			margin-right: 10px;
		}
	}

	.moreFiltersBtn {
		margin-top: 9px;
		position: relative;

		&.Button--link {
			color: var(--baseTextColor);
			font-family: var(--font-heading);
			text-decoration: none;
		}

		.Button__label {
			span {
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}

			&:hover {
				color: var(--red);
			}
		}
	}

	#SearchBarFilter {
		.searchContainer {
			.searchInput {
				border-radius: 5px 0 0 5px;
				font-size: 1.5rem;
				font-weight: 500;
			}

			.searchArrowBtn {
				border-radius: 0 5px 5px 0;
				box-sizing: border-box;
				height: 36px;
			}
		}
	}
}
