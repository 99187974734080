.debouncedInput {
	&__wrapper {
		position: relative;
		width: 100%;

		&--btnPadding input[type="text"] {
			padding-right: 37px;
		}
	}

	&__clearBtn {
		background: transparent;
		border: 0;
		font-size: 18px;
		height: 100%;
		line-height: 40px;
		margin: 0;
		position: absolute;
		right: 0;
		text-align: center;
		padding: 0 10px;
	}
}