.SortFilterAppliedFiltersCollapsible {
	background: #fff;

	.CollapsibleDisclosure {
		outline-offset: -1px;
	}

	.CollapsibleTitle {
		font-size: 18px;
		font-weight: normal;
		line-height: 32px;
		padding-left: 10px;

		&__caret::before {
			height: 10.5px;
			width: 10.5px;
		}
	}

	.CollapsibleContent {
		border: none;

		&__content {
			padding: 0;
		}
	}
}
