.CollapsibleContent {
	border-bottom: 1px solid var(--baseBorderColor);
	overflow: hidden;
	transition: height 320ms var(--surfaceTimingFunction);
	will-change: height;

	&__content {
		padding: 1rem 2.75rem 1rem 1rem;
	}
}
