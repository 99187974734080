@import '../../style/mixins';

.OverlayContent {
	--overlayContentStickyHeaderHeight: 64px;
	--overlayContentStickyFooterHeight: 50px;
	--overlayContentMaxWidth: 100%;

	background: var(--baseBackgroundColor);
	box-shadow: var(--baseBoxShadow);
	color: var(--baseTextColor);
	display: grid;
	grid-template-rows: min-content auto min-content;
	max-width: var(--overlayContentMaxWidth);

	@include media(desktop) {
		--overlayContentMaxWidth: 728px;
	}

	header {
		align-items: center;
		background: #ddd;
		box-sizing: border-box;
		display: flex;
		font-size: 21px;
		grid-row-end: 2;
		grid-row-start: 1;
		height: var(--overlayContentStickyHeaderHeight);
		padding: 0 10px;
		text-transform: uppercase;
	}

	main,
	> :not(header, footer) {
		box-sizing: border-box;
		grid-row-end: 3;
		grid-row-start: 2;
		/*
			Ideal Max Height is a calculation of
			100vh - expected header height - expected footer height - vertical margins
		*/
		max-height: calc(100vh - var(--overlayContentStickyHeaderHeight) - var(--overlayContentStickyFooterHeight) -
		(2 * var(--overlayContentWrapperMarginVertical)));
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		padding: 20px 10px;
		scroll-behavior: smooth;
	}

	footer {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		grid-row-end: 4;
		grid-row-start: 3;
		height: var(--overlayContentStickyFooterHeight);
		justify-content: center;
		padding: 10px;
	}
}

.Overlay--Dialog .OverlayContent {
	border-radius: 4px;
	min-width: 300px;
	@include media(desktop) {
		max-width: 728px;
	}
}

.Overlay--Drawer .OverlayContent {
	min-width: 300px;
}

.Overlay--Drawer-left,
.Overlay--Drawer-right {
	.OverlayContent {
		height: 100%;
	}
}

.Overlay--Drawer-top,
.Overlay--Drawer-bottom {
	.OverlayContent {
		width: 100%;
	}
}

.Overlay--Modal .OverlayContent {
	height: 100%;
}