$caretMargin: 10px;

.NestedAttributeDropdown {
	&:not(:first-child) {
		margin-top: 10px;
	}

	.Dropdown-align-top {
		top: -1px;
	}

	&__disclosure {
		cursor: pointer;
		padding: 10px;
		width: 100%;

		&--active,
		&:hover {
			background: var(--lightGrey);
		}

		&--invisible {
			visibility: hidden;
		}

		.Button__label {
			align-items: center;
			display: flex;
			justify-content: space-between;
			padding: 0;

			> .caret {
				&.left {
					margin-right: $caretMargin;
				}

				&.right {
					margin-left: $caretMargin;
				}
			}
		}
	}
}
